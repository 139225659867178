
import { defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'ModifyPassword',
  setup() {
    const router = useRouter()
    const formData = reactive({
      new: '',
      old: '',
      confirm: ''
    })
    const submit = () => {
      router.push({
        path: '/personal-center'
      })
    }
    const gotoPage = () => {
      router.back()
    }
    const gotoModifyPage = () => {
      router.push({
        path: '/modify-password'
      })
    }
    return { submit, gotoPage, gotoModifyPage, formData }
  }
})
